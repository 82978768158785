import React from 'react'
import { useContext } from 'react'
import { UniversalContext } from '../../context/UniversalContext/UniversalContext'
import styles from './Cart.module.css'
import { Button } from '@mui/material'
import { useState } from 'react'
import BookModal from '../Modal/BookModal/BookModal'
import AppointmentModal from '../Modal/AppointmentModal/AppointmentModal'

const Cart = () => {
    const { cart, setCart, cartPrice, cartPriceWithDiscount, bookModal, setBookModal } = useContext(UniversalContext);
    return (
        <>
            {cart.length>0 && <div className={styles.cont}>
                <div className={styles.inner}>
                    <div>
                        <div>
                            <i>Total Items:</i>{" "}
                            <b>{cart.length}</b>
                        </div>
                        <div>
                            <i>Total Amount:</i>{" "}
                            <s>₹{cartPrice}</s><b>{"  "}₹{cartPriceWithDiscount}</b>
                        </div>
                    </div>
                    <div>
                        <Button variant='contained' color="secondary" onClick={() => setBookModal(true)}>View Cart</Button>
                    </div>
                </div>
            </div>}
        </>

    )
}

export default Cart