import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import React, { useState, createContext, useEffect, useContext } from "react";
import { auth } from "../../firebase";
import { CircularProgress } from "@mui/material";
import WmLoader from "../../components/WmLoader/WmLoader";

export const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export const AuthContextProvider = props => {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }
    function logout() {
        return signOut(auth)
    }
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                login,
                logout,
                email, setEmail,
                password, setPassword
            }}
        >
            {loading &&
                <>
                    {/* <CircularProgress color="secondary" sx={{ display: "flex", margin: "auto", marginTop: "10rem", width: "100px !important", height: "100px !important", justifyContent: "center" }} /> */}
                    <WmLoader />
                </>

            }
            {!loading && props.children}
        </AuthContext.Provider>
    );
}