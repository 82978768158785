import React from 'react'
import styles from './WmLoader.module.css'

const WmLoader = () => {
    return (
        <div className={styles.cont}>
            <div className={styles.loader}></div>
        </div>
    )
}

export default WmLoader