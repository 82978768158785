import { LocalSeeSharp } from "@mui/icons-material";
import React, { useState, createContext } from "react";
import { useEffect } from "react";

export const UniversalContext = createContext();

export const UniversalContextProvider = props => {


    const [openSplit, setOpenSplit] = useState(false)
    const [openWindow, setOpenWindow] = useState(false)
    const [acModal, setAcModal] = useState(false)
    const [refrigeratorModal, setRefrigeratorModal] = useState(false)
    const [washingMachineModal, setWashingMachineModal] = useState(false)
    const [microwaveModal, setMicrowaveModal] = useState(false)
    const [geyserModal, setGeyserModal] = useState(false)
    const [roModal, setRoModal] = useState(false)
    const [otherServicesModal, setOtherServicesModal] = useState(false)
    const [appointmentModal, setAppointmentModal] = useState(false)
    const [bookModal, setBookModal] = useState(false)
    const [popupModal, setPopupModal] = useState(false);
    const [professionalRegistrationModal, setProfessionalRegistrationModal] = useState(false)
    const [alert, setAlert] = useState(false)
    const [openBanner, setOpenBanner] = useState(false)
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart"))||[])
    const [cartPrice, setCartPrice] = useState(0);
    const [cartPriceWithDiscount, setCartPriceWithDiscount] = useState(0);

    const removeDuplicates = (array) => {
        return Array.from(new Set(array));
    }
    function calculateTotalPrice(items) {
        return items.reduce((total, item) => total + item.price, 0);
    }
    function calculateTotalPriceWithDiscount(items) {
        return items.reduce((total, item) => total + (item.price - ((item.discount*item.price)/100)), 0);
    }
    useEffect(()=>{
        
            if(cart.length !== 0)
            {
                const newCart = removeDuplicates(cart)
                localStorage.setItem("cart", JSON.stringify(newCart))
            }
            else{
                localStorage.setItem("cart", JSON.stringify(cart))
            }
            console.log(JSON.parse(localStorage.getItem("cart")))
    },[cart])
    useEffect(()=>{
        if(localStorage.getItem("cart"))
        {
            setCartPrice(calculateTotalPrice(JSON.parse(localStorage.getItem("cart"))||cart))
            setCartPriceWithDiscount(calculateTotalPriceWithDiscount(JSON.parse(localStorage.getItem("cart"))||cart))
        }

    },[cart])

    return (
        <UniversalContext.Provider
            value={{
                openSplit, setOpenSplit,
                openWindow, setOpenWindow,
                acModal, setAcModal,
                refrigeratorModal, setRefrigeratorModal,
                washingMachineModal, setWashingMachineModal,
                microwaveModal, setMicrowaveModal,
                geyserModal, setGeyserModal,
                roModal, setRoModal,
                otherServicesModal, setOtherServicesModal,
                appointmentModal, setAppointmentModal,
                popupModal, setPopupModal,
                bookModal, setBookModal,
                professionalRegistrationModal, setProfessionalRegistrationModal,
                alert, setAlert,
                openBanner, setOpenBanner,
                cart, setCart,
                cartPrice, setCartPrice,
                cartPriceWithDiscount, setCartPriceWithDiscount
            }}
        >
            {props.children}
        </UniversalContext.Provider>
    );
}