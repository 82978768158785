import React, { useContext } from 'react';
// import styles from './App.module.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import AcServices from './pages/AcServices/AcServices';
import NavbarContained from './components/NavbarContained/NavbarContained';
import Footer from './components/Footer/Footer';
import Admin from './pages/Admin/Admin';
import PrivateRoute from './auth/PrivateRoute/PrivateRoute';
import { Alert, Snackbar } from '@mui/material';
import './pages/HomePage/global.css'
import { UniversalContext } from './context/UniversalContext/UniversalContext';
import RefrigeratorServices from './pages/RefrigeratorServices/RefrigeratorServices';
import WashingMachineServices from './pages/WashingMachineServices/WashingMachineServices';
import MicrowaveServices from './pages/MicrowaveServices/MicrowaveServices';
import GeyserServices from './pages/GeyserServices/GeyserServices';
import RoServices from './pages/RoServices/RoServices';
import OtherServices from './pages/OtherServices/OtherServices';
import Dashboard from './pages/Admin/Dashboard/Dashboard';
import GenerateLink from './pages/Admin/GenerateLink/GenerateLink';
import AcUpdatePanel from './pages/Admin/AcUpdatePanel/AcUpdatePanel';
import RefrigeratorUpdatePanel from './pages/Admin/RefrigeratorUpdatePanel/RefrigeratorUpdatePanel';
import WashingMachineUpdatePanel from './pages/Admin/WashingMachineUpdatePanel/WashingMachineUpdatePanel';
import MicrowaveUpdatePanel from './pages/Admin/MicrowaveUpdatePanel/MicrowaveUpdatePanel';
import GeyserUpdatePanel from './pages/Admin/GeyserUpdatePanel/GeyserUpdatePanel';
import RoUpdatePanel from './pages/Admin/RoUpdatePanel/RoUpdatePanel';
import OtherUpdatePanel from './pages/Admin/OtherUpdatePanel/OtherUpdatePanel';
import Messages from './pages/Admin/Messages/Messages';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import ReactWhatsapp from 'react-whatsapp';
import { Container, Button, Link } from 'react-floating-action-button'
import ActionButton from './components/ActionButton/ActionButton';
import Renting from './components/Renting/Renting';
import AcOnRent from './pages/AcOnRent/AcOnRent';
import WmOnRent from './pages/WmOnRent/WmOnRent';
import RefOnRent from './pages/RefOnRent/RefOnRent';
import MicrowaveOnRent from './pages/MicrowaveOnRent/MicrowaveOnRent';
import FanOnRent from './pages/FanOnRent/FanOnRent';
import WcOnRent from './pages/WcOnRent/WcOnRent';
import Cart from './components/Cart/Cart';
import BookModal from './components/Modal/BookModal/BookModal';
import NoShow from './pages/NoShow'

function App() {
  const { alert, bookModal } = useContext(UniversalContext)
  return (
    <Router>
      {/* //uncomment line 50 to 168, 188, 189 */}
      <Routes>
        <Route path='/' element={<NoShow />}></Route>
      </Routes>
      {/* <Routes>
        <Route path='/' element={<HomePage />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/ac/:type' element={
          <>
            <NavbarContained />
            <AcServices />
            <Cart />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/refrigerator/:type' element={
          <>
            <NavbarContained />
            <RefrigeratorServices />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/washing-machine/:type' element={
          <>
            <NavbarContained />
            <WashingMachineServices />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/microwave/:type' element={
          <>
            <NavbarContained />
            <MicrowaveServices />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/geyser/:type' element={
          <>
            <NavbarContained />
            <GeyserServices />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/ro/:type' element={
          <>
            <NavbarContained />
            <RoServices />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/other-services/:type' element={
          <>
            <NavbarContained />
            <OtherServices />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/ac-on-rent' element={
          <>
            <NavbarContained />
            <AcOnRent />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/washing-machine-on-rent' element={
          <>
            <NavbarContained />
            <WmOnRent />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/refrigerator-on-rent' element={
          <>
            <NavbarContained />
            <RefOnRent />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/microwave-on-rent' element={
          <>
            <NavbarContained />
            <MicrowaveOnRent />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/ceiling-fan-on-rent' element={
          <>
            <NavbarContained />
            <FanOnRent />
            <Cart />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/water-cooler-on-rent' element={
          <>
            <NavbarContained />
            <WcOnRent />
            <Cart />
            <Cart /><Footer />
          </>
        }></Route>
        <Route path='/admin' element={<Admin />}>
        </Route>
        <Route path='/admin/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>}></Route>
        <Route path='/admin/ac/:service' element={<PrivateRoute><AcUpdatePanel /></PrivateRoute>}></Route>
        <Route path='/admin/refrigerator/:service' element={<PrivateRoute><RefrigeratorUpdatePanel /></PrivateRoute>}></Route>
        <Route path='/admin/washing-machine/:service' element={<PrivateRoute><WashingMachineUpdatePanel /></PrivateRoute>}></Route>
        <Route path='/admin/microwave/:service' element={<PrivateRoute><MicrowaveUpdatePanel /></PrivateRoute>}></Route>
        <Route path='/admin/geyser/:service' element={<PrivateRoute><GeyserUpdatePanel /></PrivateRoute>}></Route>
        <Route path='/admin/ro/:service' element={<PrivateRoute><RoUpdatePanel /></PrivateRoute>}></Route>
        <Route path='/admin/electrical/:service' element={<PrivateRoute><OtherUpdatePanel /></PrivateRoute>}></Route>
        <Route path='/admin/link' element={<PrivateRoute><GenerateLink /></PrivateRoute>}></Route>
        <Route path='/admin/messages' element={<PrivateRoute><Messages /></PrivateRoute>}></Route>
      </Routes>
      {
        alert &&
        <Snackbar open={true} autoHideDuration={6000} onClose={false}>
          <Alert onClose={false} severity="success" sx={{ width: '100%' }}>
            Form Submitted Successfully!
          </Alert>
        </Snackbar>
      } */}


      {/* <Container>
        <Link href="#"
          tooltip="Create note link"
          icon="far fa-sticky-note" />
        <Link href="https://api.whatsapp.com/send/?phone=919654254713&text=Customer+Services+on+demand%21&type=phone_number&app_absent=0"
          tooltip="Add user link"
          icon="fas fa-user-plus" 
          className="fab-item btn btn-link btn-lg text-white" />
        <Button
          tooltip="The big plus button!"
          icon="fas fa-plus"
          rotate={true}
          />
      </Container> */}
      {/* <ActionButton /> */}
      {/* {bookModal && <BookModal />} */}
    </Router>

  );
}

export default App;
