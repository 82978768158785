import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AuthContextProvider } from './context/AuthContext/AuthContext';
import { UniversalContextProvider } from './context/UniversalContext/UniversalContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
    <UniversalContextProvider>
      <App />
    </UniversalContextProvider>
  </AuthContextProvider>
);


